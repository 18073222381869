import React, {useState} from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"

import {Container, Grid, Box, Link, Typography} from "@material-ui/core"

function XsFooter() {
	const [srcMHSRLogo, setSrcMHSRLogo] = useState("/public/images/mhsr_logo_white.png")
	const [srcSIEALogo, setSrcSIEALogo] = useState("/public/images/logo_siea_white.png")

	return (
		<div className="main_footer">
			<footer className="first_part">
				<div className="border_top"></div>
				<Container maxWidth="lg" className="footer_container">
					<Grid container justify="space-between" alignItems="center">
						<Grid item container xs alignItems="center">
							<Grid item>
								<img
									className="pointer"
									src={srcSIEALogo}
									onMouseOver={() => setSrcSIEALogo("/public/images/logo_siea_blue.png")}
									onMouseOut={() => setSrcSIEALogo("/public/images/logo_siea_white.png")}
									onClick={() => window.open("https://www.siea.sk/", "_blank")}
								/>
							</Grid>
							<Grid item style={{color: "rgba(0, 0, 0, 0.48)"}}>
								<Box ml={4}>
									<Grid container direction="column" spacing={1}>
										<Grid item>Bajkalská 27</Grid>
										<Grid item>827 99 Bratislava</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
						<Grid item>
							<img
								className="pointer"
								src={srcMHSRLogo}
								onMouseOver={() => setSrcMHSRLogo("/public/images/mhsr_logo_blue.png")}
								onMouseOut={() => setSrcMHSRLogo("/public/images/mhsr_logo_white.png")}
								onClick={() => window.open("https://www.mhsr.sk/", "_blank")}
							/>
						</Grid>
					</Grid>
				</Container>
			</footer>
			<footer className="second_part white">
				<Container maxWidth="lg" className="footer_container">
					<Typography variant="caption">
						<Grid container>
							<Grid item>
								© 2021 SIEA&nbsp;&nbsp;|&nbsp;&nbsp;<i className="fal fa-envelope"></i>&nbsp;
								<Link className="white" href="mailto:ipceime@siea.gov.sk">
									ipceime@siea.gov.sk
								</Link>
								&nbsp;&nbsp;|&nbsp;&nbsp;
								<Link
									className="white pointer"
									onClick={() => window.open("https://www.siea.sk/o-siea/ochrana-osobnych-udajov/", "_blank")}
								>
									GDPR
								</Link>
								{/* &nbsp;&nbsp;|&nbsp;&nbsp;
								<Link className="white pointer" onClick={() => logger("Podmienky používania")}>
									Term of Use
								</Link> */}
							</Grid>
							<Grid item className="pl-4">
								Created: 2021 | Changed: 2021
							</Grid>
						</Grid>
					</Typography>
				</Container>
			</footer>
		</div>
	)
}

export default injectIntl(observer(XsFooter))
