import React, {Component} from "react"
import {Switch, Route, withRouter} from "react-router-dom"
import classnames from "classnames"

import {inject, observer} from "mobx-react"

import XsSnackbar from "./global/ui/xsSnackbar/xsSnackbar"
// import XsButton from "./global/ui/xsButton/xsButton"
import XsHeader from "./global/ui/xsHeader/xsHeader"
import XsFooter from "./global/ui/xsFooter/xsFooter"
import XsLoading from "./global/ui/xsLoading/xsLoading"

import LoginContainer from "./modules/login/loginContainer"
import Register from "./modules/login/register"
import RequestForm from "./modules/RequestForm/RequestForm"
import MicroelectronicFrom from "./modules/microelectronic/microelectronicForm"
import RegisterContainer from "./modules/login/registerContainer"
import ResetPasswordContainer from "./modules/login/resetPasswordContainer"
import XsInfoDialog from "./global/ui/xsDialog/xsInfoDialog"
import XsErrorDialog from "./global/ui/xsDialog/xsErrorDialog"
import XsConfirmationDialog from "./global/ui/xsDialog/xsConfirmationDialog"

import Requests from "./modules/requests/requests"
import WelcomePage from "./modules/welcome/welcomePage"
// import Profil from "./modules/profil/profil"

import InfoStore from "./global/store/InfoStore"
import ErrorStore from "./global/store/ErrorStore"
import RouterStore from "./global/store/RouterStore"
import GlobalStore from "./global/store/GlobalStore"

import {getUser, logout, echo} from "./global/helpers/actions"

import "./global/styles/global.less"

@inject("routing")
@withRouter
@observer
export default class App extends Component {
	componentDidMount() {
		const user = getUser()

		if (
			isEmpty(user) &&
			RouterStore.location.pathname != "/" &&
			RouterStore.location.pathname != "/login" &&
			RouterStore.location.pathname != "/registerFinish" &&
			RouterStore.location.pathname != "/passwordResetFinish"
		) {
			RouterStore.push("/login")
		}
	}

	render() {
		const user = getUser()
		const id = new URLSearchParams(this.props.routing.location.search).get("id")

		return (
			<div
				id="app"
				style={
					RouterStore.location.pathname == "/welcome" || RouterStore.location.pathname == "/requests"
						? {backgroundImage: "url(/public/images/logo_mikroelektronics_watermark.png)"}
						: {}
				}
				className={classnames({
					"main-bg": RouterStore.location.pathname == "/welcome" || RouterStore.location.pathname == "/requests"
				})}
			>
				{isSafe(user) ? (
					<Switch>
						<Route
							exact
							path="/"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<LoginContainer {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/login"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<LoginContainer {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/register"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<Register {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/welcome"
							render={(props) => (
								<React.Fragment>
									<XsHeader />
									<main className="main_container">
										<WelcomePage {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/registerFinish"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<RegisterContainer {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/passwordResetFinish"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<ResetPasswordContainer {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/microelectronic"
							render={(props) => (
								<React.Fragment>
									<XsHeader />
									<main className="main_container">
										<MicroelectronicFrom id={id} {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/request"
							render={(props) => (
								<React.Fragment>
									<XsHeader />
									<main className="main_container">
										<RequestForm id={id} {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						{/* <Route
							exact
							path="/profil"
							render={(props) => (
								<React.Fragment>
									<XsHeader />
									<Profil {...props} />
									<XsFooter />
								</React.Fragment>
							)}
						/> */}
						<Route
							exact
							path="/requests"
							render={(props) => (
								<React.Fragment>
									<XsHeader />
									<main className="main_container">
										<Requests {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
					</Switch>
				) : (
					<Switch>
						<Route
							exact
							path="/"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<LoginContainer {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/login"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<LoginContainer {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/register"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<Register {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/registerFinish"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<RegisterContainer {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
						<Route
							exact
							path="/passwordResetFinish"
							render={(props) => (
								<React.Fragment>
									<main className="main_container">
										<ResetPasswordContainer {...props} />
									</main>
									<XsFooter />
								</React.Fragment>
							)}
						/>
					</Switch>
				)}
				<XsSnackbar />
				{GlobalStore.isLoading && <XsLoading overlay />}
				{ErrorStore.isOpen && <XsErrorDialog />}
				{InfoStore.isOpen && <XsInfoDialog />}
				<XsConfirmationDialog
					name="xsWarningTokenExpired"
					text={`Your session will expire in ${GlobalStore.countdownExpireToken} seconds!`}
					confirmBtn={{
						icon: <i className="fal fa-check" />,
						text: "Common.btn.renew"
					}}
					cancelBtn={{
						icon: <i className="fal fa-times" />,
						text: "Common.btn.logout"
					}}
					onConfirmation={() => {
						echo()
					}}
					onCancel={() => {
						logout()
					}}
				/>
			</div>
		)
	}
}
