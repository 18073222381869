"use strict"

import React from "react"
import {observer} from "mobx-react"

import {injectIntl} from "react-intl"
import {Container, Typography, Grid, Link, Box} from "@material-ui/core"

import Countdown, {zeroPad} from "react-countdown"

import GlobalStore from "../../global/store/GlobalStore"

const Completionist = () => (
	<Grid container justify="center">
		<Grid item>
			<Typography variant="h6" className="header_gradient">
				Application registration is currently closed.
			</Typography>
		</Grid>
	</Grid>
)

const renderer = ({hours, minutes, seconds, days, completed}) => {
	if (completed) {
		// Render a complete state
		return <Completionist />
	} else {
		// Render a countdown
		const day = isNotEmpty(days) && days > 99 ? days : days > 9 ? `0${days}` : `00${days}`

		return (
			<React.Fragment>
				<Grid container justify="center">
					<Grid item>
						<Typography variant="h6" className="header_gradient">
							IPCEI Registration closes in...
						</Typography>
					</Grid>
				</Grid>
				<Grid container justify="center" className="pt-1">
					<Grid
						item
						xs={1}
						style={{width: 90, minWidth: 90}}
						container
						direction="column"
						alignItems="center"
						className="mr-4"
					>
						<Grid item className="countdown-item">
							<Typography variant="h3">{day}</Typography>
						</Grid>
						<Grid item>
							<Typography className="blueLight" variant="body2">
								{day > 1 ? "days" : "day"}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={1}
						style={{width: 65, minWidth: 65}}
						container
						direction="column"
						alignItems="center"
						className="mr-3"
					>
						<Grid item className="countdown-item">
							<Typography variant="h3">{zeroPad(hours)}</Typography>
						</Grid>
						<Grid item>
							<Typography className="blueLight" variant="body2">
								{hours > 1 ? "hours" : "hour"}
							</Typography>
						</Grid>
					</Grid>
					<Grid
						item
						xs={1}
						style={{width: 65, minWidth: 65}}
						container
						direction="column"
						alignItems="center"
						className="mr-3"
					>
						<Grid item className="countdown-item">
							<Typography variant="h3">{zeroPad(minutes)}</Typography>
						</Grid>
						<Grid item>
							<Typography className="blueLight" variant="body2">
								{minutes > 1 ? "minutes" : "minute"}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={1} style={{width: 65, minWidth: 65}} container direction="column" alignItems="center">
						<Grid item className="countdown-item">
							<Typography variant="h3">{zeroPad(seconds)}</Typography>
						</Grid>
						<Grid item>
							<Typography className="blueLight" variant="body2">
								{seconds > 1 ? "seconds" : "second"}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</React.Fragment>
		)
	}
}

function WelcomePage() {
	return (
		<Container maxWidth="md">
			<Grid container direction="column" spacing={2}>
				<Grid item container justify="center">
					<Grid item>
						<Box pt={8} pb={6}>
							<Typography variant="h3" className="header_gradient">
								Welcome to IPCEI portal
							</Typography>
						</Box>
					</Grid>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						&ldquo;IPCEI Registration forms (applications)&rdquo; link in the top menu redirects to a list of your
						applications. By pressing the &ldquo;New IPCEI Registration form (application)&rdquo; button in this list
						you can create a new application.
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						If you want to exit the portal, please log out by clicking on &ldquo;Log out&rdquo; link in the top menu.
						{/* Ak chcete ukončiť prácu v portáli, odhláste sa prosím klikom na &ldquo;Odhlásiť sa&rdquo; v pravej hornej
						časti obrazovky. */}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						{/* Ak máte akékoľvek otázky, kontaktujte SIEA infolinku na telefónnom čísle 02/58 248 111, alebo nám napíšte na */}
						If you have any questions, contact the SIEA infoline on the phone number 02/58 248 111, or write to us at
						<Link href="mailto:ipceime@siea.gov.sk" className="bold">
							{" "}
							ipceime@siea.gov.sk
						</Link>
					</Typography>
				</Grid>
				<Grid item>
					<Box pt={3}>
						<Countdown date={GlobalStore.registrationCloseIn} renderer={renderer} />
					</Box>
				</Grid>
			</Grid>
		</Container>
	)
}

export default injectIntl(observer(WelcomePage))
