import {FormattedMessage} from "react-intl"
import React from "react"
import {getPerson, getUser} from "../../global/helpers/actions"
// import moment from "moment"

export default {
	load() {
		const mail = isSafe(getUser()) && isSafe(getUser().user) ? getUser().user.email : ""
		return {
			fields: {
				request_id: {},
				orgunit_id: {},
				status: {},
				dropzone: {},
				applicantRegion: {
					label: "Region",
					rules: "required"
				},
				applicantCountry: {
					label: "Country",
					rules: "required",
					value: "SK"
				},
				applicantCity: {
					label: "Town",
					rules: "required"
				},
				companyName: {
					label: <FormattedMessage id="Common.label.companyName" />,
					rules: "required"
				},
				// statutar: {
				// 	label: <FormattedMessage id="Common.label.statutarName" />,
				// 	rules: "required"
				// },
				companyStreet: {
					label: "Street"
				},
				companyStreetNumber: {
					label: "House number"
				},
				companyCity: {
					label: "Town (Start typing to fill the input)",
					rules: "required"
				},
				companyPSC: {
					label: "Zip",
					rules: "required"
				},
				company_id: {},
				ico: {
					label: <FormattedMessage id="Common.label.ico" />,
					rules: "required|between:8,8"
				},
				companyRegion: {
					label: "Region",
					rules: "required"
				},
				companyCounty: {
					label: "County",
					rules: "required"
				},
				// consortiumYes: {
				// 	label: <FormattedMessage id="Common.label.consortiumYes" />,
				// 	type: "checkbox"
				// },
				// multiCompanyName: {},
				// multiStatutar: {},
				// multiIco: {},

				// date: {
				// 	label: "Dátum",
				// 	type: "date",
				// 	rules: "date"
				// },
				//________-Kontaktné údaje žiadateľa
				name: {
					label: <FormattedMessage id="Common.label.name" />,
					rules: "required",
					value: getPerson().first_name
				},
				surname: {
					label: <FormattedMessage id="Common.label.surname" />,
					rules: "required",
					value: getPerson().last_name
				},
				person_id: {},
				mail: {
					label: <FormattedMessage id="Common.label.email" />,
					rules: "required|email",
					value: mail
				},
				phone: {
					label: <FormattedMessage id="Common.label.phone" />,
					rules: "required"
				},
				contactDescription: {
					label: "Company presentation (summary)",
					rules: "required"
				},
				contactCountry: {
					label: "Country",
					rules: "required",
					value: "SK"
				},
				// contactLocation: {
				// 	label: "Poloha",
				// rules: "required"
				// },
				sectorClearHydrogen: {
					label: "Clean Hydrogen Production",
					type: "checkbox"
				},
				sectorHydrogenDistribution: {
					label: "Hydrogen Transmission and Distribution",
					type: "checkbox"
				},
				sectorHydrogenMobility: {
					label: "Hydrogen Mobility Applications ",
					type: "checkbox"
				},
				sectorHydrogenInIndustry: {
					label: "Hydrogen Applications in Industry",
					type: "checkbox"
				},
				sectorHydrogenInEnergy: {
					label: "Hydrogen Applications in the Energy Sector",
					type: "checkbox"
				},
				sectorHydrogenInHousing: {
					label: "Residential Hydrogen Applications",
					type: "checkbox"
				},
				sectorHydrogenComponents: {
					label: "Components",
					type: "checkbox"
				},

				productionRenewable: {
					label: "Renewable",
					type: "checkbox"
				},
				productionLowCO2prod: {
					label: "Low Carbon",
					type: "checkbox"
				},
				productionOther: {
					label: "Other (explain)",
					type: "checkbox"
				},
				productionOtherDescription: {
					label: "Explain"
				},
				activityTypeInfrastructure: {
					label: "Infrastructure (including refuelling stations)",
					type: "checkbox"
				},
				activityTypeEquipProduction: {
					label: "Equipment manufacturing (research, development, innovation / first industrial deployment)",
					type: "checkbox"
				},
				activityTypeHydrogenProduction: {
					label: "Hydrogen production",
					type: "checkbox"
				},
				activityTypeIndustryUse: {
					label: "Industrial application (research, development, innovation / first industrial deployment)",
					type: "checkbox"
				},
				activityTypeOther: {
					label: "Other (please specify)",
					type: "checkbox"
				},
				activityTypeOtherText: {
					label: "Please specify"
				},
				//________-Udaje o projekte
				projectName: {
					label: <FormattedMessage id="Common.label.projectName" />,
					rules: "required"
				},
				projectAcronym: {
					label: <FormattedMessage id="Common.label.projectAcronym" />
				},
				plannedProjectStart: {
					type: "date",
					label: "Obdobie pripravenosti"
				},
				plannedProjectStartYear: {
					label: "Readiness date (Planned project start date) Year",
					rules: "required"
				},
				plannedProjectStartMonth: {
					label: "Readiness date (Planned project start date) Month",
					rules: "required"
				},
				plannedProjectCapacaty: {
					label: "Planned capacity per year (if relevant)  (PJ / GW / kt/ production units)"
				},
				plannedInvestment: {
					label: "Planned Investment scale (CAPEX) (in millions EUR)",
					rules: "required"
				},
				plannedSector: {
					label: "Sub-pillar (Further specification of sector the project is involved in)"
				},
				projectSummary: {
					label: "Project Summary",
					rules: "required"
				},
				plannedOpex: {
					label:
						"Annual OPEX (in millions  EUR) Including all production inputs (lease, electricity, HR, raw materials, etc.)",
					rules: "required"
				},
				specificProjectDescription: {
					label:
						"Detailed Project Description (use the project description from Project Portfolio, part 1 Project Outline)",
					rules: "required"
				},
				projectValueForHydrogenEcosystem: {
					label:
						"How does the individual project add value/contribute to the Hydrogen ecosystem which significantly contributes to the goals of Hydrogen strategy for a climate-neutral Europe (e.g. to the reduction of greenhouse gas emissions etc.)",
					rules: "required"
				},
				plannedGoals: {
					label: "Quantitative targets for scale-up 2024 and 2030 (if available)"
				},
				projectEnablesPartnersTo: {
					label: "Our project offers to partners...(Explain the spill-overs that you can offer to other partners)",
					rules: "required"
				},
				projectLooksForPartnersFrom: {
					label:
						"Our project searches partners for...(Explain what partners you are looking for (companies, research organisations etc.) and in what industry or field of study)",
					rules: "required"
				},
				projectNeedsStatesHelp: {
					label:
						"Why can the project not be implemented already and or without State aid? Technical challenges/uncertainties/regulatory uncertainties/commercial uncertainties/lack of capital/other (please specify)",
					rules: "required"
				},
				projectAssumptions: {
					label: "Prerequisites (Which inputs, events, regulations need to happen so the project could be implemented)",
					rules: "required"
				}
			}
		}
	}
}
