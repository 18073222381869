"use strict"

import React, {useEffect} from "react"
import {observer} from "mobx-react"
import moment from "moment"

import {FormattedMessage, injectIntl} from "react-intl"
import {Container, Box, Grid, Typography} from "@material-ui/core"

import XsButton from "../../global/ui/xsButton/xsButton"
import XsTable from "../../global/ui/xsTable/xsTable"

import RequestStore from "./requestStore"
import GlobalStore from "../../global/store/GlobalStore"
import RouterStore from "../../global/store/RouterStore"

const columns = [
	{
		Header: <FormattedMessage id="Common.label.createDate" />,
		width: 150,
		accessor: "created_at",
		sortType: (rowA, rowB) => {
			const created_at_A = rowA.original.created_at
			const created_at_B = rowB.original.created_at
			if (created_at_A > created_at_B) return -1
			if (created_at_B > created_at_A) return 1
			return 0
		},
		Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
	},
	{
		Header: <FormattedMessage id="Common.label.sentDate" />,
		width: 150,
		accessor: "sent_date_time",
		sortType: (rowA, rowB) => {
			const send_date_time_A = isNotEmpty(rowA.original.sent_date_time) ? rowA.original.sent_date_time : ""
			const send_date_time_B = isNotEmpty(rowB.original.sent_date_time) ? rowB.original.send_date_time : ""
			if (send_date_time_A > send_date_time_B) return -1
			if (send_date_time_B > send_date_time_A) return 1
			return 0
		},
		Cell: (props) => <div>{isNotEmpty(props.value) ? moment(props.value).format("DD.MM.YYYY HH:mm") : ""}</div>
	},
	{
		Header: <FormattedMessage id="Common.label.state" />,
		width: 120,
		accessor: "status.name"
	},
	{
		Header: <FormattedMessage id="Common.label.companyName" />,
		width: 200,
		accessor: "company_data.name",
		sortType: "basic"
	},
	{
		Header: <FormattedMessage id="Common.label.projectName" />,
		width: 200,
		accessor: "project_name",
		sortType: "basic",
		Cell: (props) => <div>{isSafe(props.value) ? props.value.shortTo(100) : ""}</div>
	},
	{
		Header: <FormattedMessage id="Common.label.amount" />,
		width: 150,
		accessor: "planned_investment_scale",
		sortType: "basic"
	}
]

function Requests() {
	useEffect(() => {
		RequestStore.getRequests()
	}, [])

	return (
		<Container maxWidth="lg">
			<Grid container direction="column">
				<Grid item>
					<Box pt={2} pb={2}>
						<Typography variant="h4" className="header_gradient">
							List of your IPCEI Registration forms (applications)
						</Typography>
					</Box>
				</Grid>
				<Grid item>
					<Typography variant="body2">
						Click a row in the list to edit the IPCEI Registration form items. An IPCEI Registration form detail will
						open in which you can make changes and then save them.
					</Typography>
					{/* Ak chcete upraviť položky žiadosti, kliknite na riadok v zozname. Otvorí sa vám detail žiadosti, v ktorom
					môžete vykonať zmeny a tieto následne uložiť. */}
				</Grid>
			</Grid>
			<Box pt={2}>
				<Grid container>
					<Grid item xs={12} container justify="flex-end">
						<Grid item>
							<XsButton
								disabled={GlobalStore.isRegistrationClose()}
								text="New IPCEI Registration form (application)"
								icon={<i className="fas fa-plus"></i>}
								onClick={() => RouterStore.push("/microelectronic")}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Box pt={1}>
							<XsTable
								columns={columns}
								data={RequestStore.data}
								onRowClick={(row) => RouterStore.push(`/microelectronic?id=${row.record_id}`)}
							/>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Container>
	)
}

export default injectIntl(observer(Requests))
