"use strict"

import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"

import fields from "./fields"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import api from "../../global/actions/api"

import {Container, Grid, Typography, Link} from "@material-ui/core"

import {FormattedMessage, injectIntl} from "react-intl"
import XsInput from "../../global/ui/xsInput/xsInput"
import XsAutocomplete from "../../global/ui/xsInput/xsAutocomplete"
import XsNumberInput from "../../global/ui/xsInput/xsNumberInput"
import dvr from "mobx-react-form/lib/validators/DVR"
import XsTextArea from "../../global/ui/xsTextArea/xsTextArea"
import GlobalStore from "../../global/store/GlobalStore"
import Dropzone from "react-dropzone"
import XsConfirmationDialog from "../../global/ui/xsDialog/xsConfirmationDialog"
import XsCheckbox from "../../global/ui/xsCheckbox/xsCheckbox"
import XsButton from "../../global/ui/xsButton/xsButton"

import InfoStore from "../../global/store/InfoStore"
import RequestFormStore from "./RequestFormStore"
import RouterStore from "../../global/store/RouterStore"
import XsSearchSelect from "../../global/ui/xsSearchSelect/xsSearchSelect"
import RequestPrintStore from "./RequestPrintStore"
import RegistersCacheStore from "../../global/store/RegistersCacheStore"
import {isPhoneNumberValid} from "../../global/helpers/functions"

// import XsMultiSelect from "../../global/ui/xsMultiSelect/xsMultiSelect"
// import XsPeriodPicker from "../../global/ui/xsPeriodPicker/xsPeriodPicker"
// import XsSimpleTextArea from "../../global/ui/xsTextArea/xsSimpleTextArea"
// import XsDateTimePicker from "../../global/ui/xsDateTimePicker/xsDateTimePicker"

function RequestForm(props) {
	const requiredAttachemnts = ["annexsd", "annexepb", "annexppt", "sumprojsk"]
	const hooks = {
		onSubmit(form) {
			for (let index in requiredAttachemnts) {
				if (RequestFormStore[`dropedFiles${index}`].length < 1) {
					form.$("dropzone").invalidate("minimum")
					GlobalStore.setNotificationMessage(
						"Application can't be submitted, no mandatory files attached! Please attach mandatory documents first.",
						null,
						"error"
					)
				}
			}

			if (isNotEmpty(form.$("phone").value)) {
				form.$("phone").value = form.$("phone").value.replace(/ /g, "")
				if (!isPhoneNumberValid(form.$("phone").value)) {
					form.$("phone").invalidate("incorect format")
					GlobalStore.setNotificationMessage("Phone number is not valid!", null, "error")
				}
			}

			if (isNotEmpty(form.$("companyPSC").value)) {
				form.$("companyPSC").value = form.$("companyPSC").value.replace(/ /g, "")
				if (form.$("companyPSC").value.length != 5) {
					form.$("companyPSC").invalidate("incorect format")
					GlobalStore.setNotificationMessage("Zip is not valid!", null, "error")
				}
			}

			// else {
			// 	if (
			// 		RequestFormStore.attachmentTemplates
			// 			.filter((row) => requiredAttachemnts.includes(row.attachment_type_id))
			// 			.some(
			// 				(temp) =>
			// 					!RequestFormStore.dropedFiles.some((file) => {
			// 						return file.name.toLowerCase().includes(temp.attachment_type_name.toLowerCase())
			// 					})
			// 			)
			// 	) {
			// 		form.$("dropzone").invalidate("minimum")
			// 		GlobalStore.setNotificationMessage(
			// 			"Application can't be submitted, no mandatory files attached! Please attach mandatory documents first.",
			// 			null,
			// 			"error"
			// 		)
			// 	}
			// }

			if (
				form.$("activityTypeInfrastructure").value == false &&
				form.$("activityTypeEquipProduction").value == false &&
				form.$("activityTypeHydrogenProduction").value == false &&
				form.$("activityTypeIndustryUse").value == false &&
				form.$("activityTypeOther").value == false
			) {
				form.$("activityTypeInfrastructure").invalidate("minimum one")
				GlobalStore.setNotificationMessage(
					"Application can't be submitted, you need to choose at least one Type of activity",
					null,
					"error"
				)
			}

			if (
				form.$("sectorClearHydrogen").value == false &&
				form.$("sectorHydrogenDistribution").value == false &&
				form.$("sectorHydrogenMobility").value == false &&
				form.$("sectorHydrogenInIndustry").value == false &&
				form.$("sectorHydrogenInEnergy").value == false &&
				form.$("sectorHydrogenInHousing").value == false &&
				form.$("sectorHydrogenComponents").value == false
			) {
				form.$("sectorClearHydrogen").invalidate("minimum one")
				GlobalStore.setNotificationMessage(
					"Application can't be submitted, you need to choose at least one Pillar",
					null,
					"error"
				)
			}

			if (
				form.$("sectorClearHydrogen").value == true &&
				form.$("productionRenewable").value == false &&
				form.$("productionLowCO2prod").value == false &&
				form.$("productionOther").value == false
			) {
				form.$("productionRenewable").invalidate("minimum one")
				GlobalStore.setNotificationMessage(
					"Application can't be submitted, you need to choose at least one Type of hydrogen produced",
					null,
					"error"
				)
			}
		},
		onSuccess(form) {
			logger("SAVE", form.values())
			RequestFormStore.generateDataAndSave(form, "final", () => {
				RouterStore.push("/requests")
				GlobalStore.loading(false)
			})
		},
		onError(form) {
			logger("CHYBY:", form.errors())
			GlobalStore.setNotificationMessage("Check required fields!", null, "error")
		}
	}

	const [form] = useState(new MobxReactForm(fields.load(), {plugins: {dvr: dvr(validatorjs)}, hooks}))
	// const [consortiumRowNumber, setConsortiumRowNumber] = useState(0)

	useEffect(() => {
		RequestFormStore.formRef = form
		RequestFormStore.resetDropzones()
		if (isNotEmpty(props.id)) {
			RequestFormStore.loadCodelistAndForm(form, props.id)
		} else {
			RequestFormStore.defaultFormValues = form.values()
			RegistersCacheStore.checkRegisters("/request")
		}
		RequestFormStore.loadAttachemntTemplates()
	}, [])

	const handleDrop = (files, index, length) => {
		logger(files)
		for (let fileIndex in files) {
			let file = files[fileIndex]
			if (file.size > 5242880) {
				GlobalStore.setNotificationMessage("Size limit exceeded!", null, "error")
				continue
			}
			if (RequestFormStore[`dropedFiles${index}`].length >= length) {
				GlobalStore.setNotificationMessage("Maximum number of files exceeded!", null, "error")
				continue
			}

			if (index != "Other") {
				if (index == 0) {
					file.databaseType = "annexppt"
				} else if (index == 1) {
					file.databaseType = "annexepb"
				} else if (index == 2) {
					file.databaseType = "annexsd"
				} else if (index == 3) {
					file.databaseType = "sumprojsk"
				}
			} else {
				file.databaseType = "General"
			}
			RequestFormStore[`dropedFiles${index}`].push(file)
		}
	}

	const handleDropzoneDelete = (attachmentIndex, dropzoneIndex) => {
		if (isSafe(RequestFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex]._id)) {
			RequestFormStore.deleteLoadedAttachemnt(
				RequestFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex]._id,
				dropzoneIndex,
				attachmentIndex
			)
		} else {
			RequestFormStore[`dropedFiles${dropzoneIndex}`].splice(attachmentIndex, 1)
		}
	}

	const handleDropzonePreview = (attachmentIndex, dropzoneIndex) => {
		var FileSaver = require("file-saver") // eslint-disable-line no-undef
		FileSaver.saveAs(
			RequestFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex],
			RequestFormStore[`dropedFiles${dropzoneIndex}`][attachmentIndex].name
		)
	}

	const isFormDisabled = () => {
		return (
			Boolean(form.$("status").value != "inp" && isNotEmpty(form.$("status").value)) ||
			GlobalStore.isRegistrationClose()
		)
	}

	// const addConsortiumRow = () => {
	//   const idx = consortiumRowNumber + 1
	//   setConsortiumRowNumber(idx)
	//   form.$("multiCompanyName").add(
	//     {
	//       key: idx,
	//       label: `${props.intl.formatMessage({ id: "Common.label.companyName" })}`,
	//       rules: "required"
	//     },
	//     true
	//   )
	//   form.$("multiStatutar").add(
	//     {
	//       key: idx,
	//       label: `${props.intl.formatMessage({ id: "Common.label.statutarName" })}`,
	//       rules: "required"
	//     },
	//     true
	//   )
	//   form.$("multiIco").add(
	//     {
	//       key: idx,
	//       label: `${props.intl.formatMessage({ id: "Common.label.ico" })}`,
	//       rules: "required"
	//     },
	//     true
	//   )
	// }

	// const removeConsortiumRow = (index) => {
	//   if (isNotEmpty(index)) {
	//     form.$("multiCompanyName").del(index.toString())
	//     form.$("multiStatutar").del(index.toString())
	//     form.$("multiIco").del(index.toString())
	//   }
	// }

	// const removeAllConsortiumRows = () => {
	//   for (let index = 1; index <= consortiumRowNumber; index++) {
	//     index = index.toString()
	//     if (form.$("multiCompanyName").fields.has(index) &&
	//       form.$("multiStatutar").fields.has(index) &&
	//       form.$("multiIco").fields.has(index)
	//     ) {
	//       form.$("multiCompanyName").del(index)
	//       form.$("multiStatutar").del(index)
	//       form.$("multiIco").del(index)
	//     }
	//   }
	// }

	// const renderConsortiumRows = () => {
	//   let rows = []
	//   for (let index = 1; index <= consortiumRowNumber; index++) {
	//     if (form.$("multiCompanyName").fields.has(index.toString()) &&
	//       form.$("multiStatutar").fields.has(index.toString()) &&
	//       form.$("multiIco").fields.has(index.toString())
	//     ) {
	//       rows.push(
	//         <React.Fragment>
	//           <Grid item xs={3}>
	//             <XsInput field={form.$("multiCompanyName").fields.get(index.toString())} />
	//           </Grid>
	//           <Grid item xs={4}>
	//             <XsInput field={form.$("multiStatutar").fields.get(index.toString())} />
	//           </Grid>
	//           <Grid item xs={3}>
	//             <XsNumberInput field={form.$("multiIco").fields.get(index.toString())} step={"1"} min={"1"} />
	//           </Grid>
	//           <Grid item xs={1}>
	//             <XsButton color="secondary" text={<FormattedMessage id="Common.label.remove" />} icon={<i className="fal fa-times" />} onClick={() => removeConsortiumRow(index)} />
	//           </Grid>
	//           {index == consortiumRowNumber && <Grid item xs={1}>
	//             <XsButton text={<FormattedMessage id="Common.label.add" />} icon={<i className="fal fa-plus" />} onClick={() => { addConsortiumRow() }} />
	//           </Grid>}
	//         </React.Fragment>
	//       )
	//     }
	//   }
	//   return rows
	// }

	const generateYearItems = () => {
		let data = []
		for (let i = 20; i <= 50; i++) {
			data.push({
				_id: `20${i}`,
				code: `20${i}`,
				name_ext: `20${i}`
			})
		}
		return data
	}

	const generateMonthItems = () => {
		let data = []
		for (let i = 1; i <= 12; i++) {
			data.push({
				_id: `${i < 10 ? "0" : ""}${i}`,
				code: `${i < 10 ? "0" : ""}${i}`,
				name_ext: `${i < 10 ? "0" : ""}${i}`
			})
		}
		return data
	}

	return (
		<React.Fragment>
			<Container maxWidth="lg">
				<Container maxWidth="lg" className="request_form_fields">
					<Grid container direction="column" spacing={2} className="pt-5">
						<Grid item>
							<Typography variant="body2">
								Please fill in the IPCEI Registration form (application) fields and press the{" "}
								<span className="bold">Save</span> button, otherwise your changes will be lost! You can return to this
								form later to complete it.
								{/* Vyplňte prosím položky žiadosti a stlačte tlačidlo <b>Uložiť</b>, inak sa vaše zmeny stratia! K rozpísanej
							žiadosti sa po uložení viete opätovne vrátiť a doplniť ju. */}
							</Typography>
							<Typography variant="body2">
								When you are sure that your IPCEI Registration form (application) is completed, press the{" "}
								<span className="bold">Submit</span> button - this will send the IPCEI Registration form (application)
								for processing and changes to it will no longer be possible.
							</Typography>
							{/* Keď ste si istí, že je žiadosť z vašej strany kompletne vyplnená, stlačte tlačidlo <b>Odoslať</b> - týmto
							žiadosť odošlete na spracovanie a zmeny v nej už nebude možné uskutočniť. */}
						</Grid>
						<Grid item>
							<Typography variant="subtitle2">Fields marked with an * are required.</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={2} className="pt-5">
						<Grid item xs={12}>
							<Typography variant="h5">
								<FormattedMessage id="Text.requesterInfo" />
							</Typography>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("companyName")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsNumberInput field={form.$("ico")} step={"1"} min={"1"} disabled={isFormDisabled()} />
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="h6">Registered seat of company</Typography>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("contactCountry")}
								items={GlobalStore.CL["countries"]}
								required
								disabled={isFormDisabled() || isNotEmpty(form.$("request_id").value)}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("companyRegion")}
								items={GlobalStore.CL["addressRegion"]}
								required
								disabled={isFormDisabled()}
								onChange={() => {
									form.$("companyCounty").value = ""
									form.$("companyCity").value = ""
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("companyCounty")}
								items={
									isNotEmpty(form.$("companyRegion").value) && isSafe(GlobalStore.CL["addressCounty"])
										? GlobalStore.CL["addressCounty"].filter((row) => {
												return row.region == form.$("companyRegion").value
										  })
										: GlobalStore.CL["addressCounty"]
								}
								required
								disabled={isFormDisabled()}
								onChange={() => {
									form.$("companyCity").value = ""
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsAutocomplete
								field={form.$("companyCity")}
								minLengthForSearch="1"
								api={api.loadEntityCity}
								inputRenderer={(obj) => obj.name_ext}
								saveValue={(obj) => obj.code}
								disabled={isFormDisabled()}
								addSpecialFilter={() => {
									if (isNotEmpty(form.$("companyCounty").value)) {
										return [
											{
												associated_column: "county",
												predicate: "=",
												values: [
													{
														id_value: form.$("companyCounty").value
													}
												]
											}
										]
									}
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("companyStreet")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("companyStreetNumber")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("companyPSC")} disabled={isFormDisabled()} />
						</Grid>
						{/* <Grid item xs={3}>
            <XsInput field={form.$("statutar")} />
          </Grid> */}

						{/* <Grid item xs={12}>
            <XsCheckbox field={form.$("consortiumYes")} onChange={() => {
              if (form.$("consortiumYes").value) {
                setConsortiumRowNumber(0)
                addConsortiumRow()
              } else {
                removeAllConsortiumRows()
                setConsortiumRowNumber(0)
              }
            }} />
          </Grid>
          {renderConsortiumRows()} */}
					</Grid>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="h6">
								<FormattedMessage id="Text.contactInfo" />
							</Typography>
						</Grid>

						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("name")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("surname")} disabled={isFormDisabled()} />
						</Grid>

						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("mail")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsInput field={form.$("phone")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<XsTextArea field={form.$("contactDescription")} disabled={isFormDisabled()} maxLength={3000} />
						</Grid>
					</Grid>
					<Grid container spacing={2} className="pt-5">
						<Grid item xs={12}>
							<Typography variant="h5">
								<FormattedMessage id="Text.projectInfo" />
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<XsInput field={form.$("projectName")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<XsInput field={form.$("projectAcronym")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6">Location</Typography>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<XsSearchSelect
								field={form.$("applicantCountry")}
								items={GlobalStore.CL["countries"]}
								required
								disabled={isFormDisabled() || isNotEmpty(form.$("request_id").value)}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							{/* <XsInput field={form.$("applicantRegion")} disabled={isFormDisabled()} /> */}
							<XsSearchSelect
								field={form.$("applicantRegion")}
								items={GlobalStore.CL["addressRegion"]}
								required
								disabled={isFormDisabled()}
								onChange={() => {
									form.$("applicantCity").value = ""
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							{/* <XsInput field={form.$("applicantCity")} disabled={isFormDisabled()} /> */}
							<XsAutocomplete
								field={form.$("applicantCity")}
								minLengthForSearch="1"
								api={api.loadEntityCity}
								inputRenderer={(obj) => obj.name_ext}
								saveValue={(obj) => obj.code}
								disabled={isFormDisabled()}
								addSpecialFilter={() => {
									if (isNotEmpty(form.$("applicantRegion").value)) {
										return [
											{
												associated_column: "region",
												predicate: "=",
												values: [
													{
														id_value: form.$("applicantRegion").value
													}
												]
											}
										]
									}
								}}
							/>
						</Grid>
					</Grid>
					{/* <Grid item xs={3}>
            <XsSearchSelect field={form.$("contactLocation")} />
          </Grid> */}

					<Grid container direction="column" className="pt-2">
						<Grid item>
							<Typography variant="h6">Type of activity *</Typography>
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("activityTypeInfrastructure")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("activityTypeEquipProduction")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox
								field={form.$("activityTypeHydrogenProduction")}
								onChange={() => {
									if (form.$("activityTypeHydrogenProduction").value) {
										form.$("plannedProjectCapacaty").set("rules", "required")
									} else {
										form.$("plannedProjectCapacaty").set("rules", "")
									}
								}}
								disabled={isFormDisabled()}
							/>
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("activityTypeIndustryUse")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox
								field={form.$("activityTypeOther")}
								disabled={isFormDisabled()}
								onChange={() => {
									if (form.$("activityTypeOther").value) {
										form.$("activityTypeOtherText").set("rules", "required")
									} else {
										form.$("activityTypeOtherText").set("rules", "")
										form.$("activityTypeOtherText").value = ""
									}
								}}
							/>
							{form.$("activityTypeOther").value && (
								<XsTextArea field={form.$("activityTypeOtherText")} disabled={isFormDisabled()} />
							)}
						</Grid>
					</Grid>
					<Grid container spacing={2} className="pt-2">
						<Grid item xs={12} md={6}>
							<XsSearchSelect
								field={form.$("plannedProjectStartYear")}
								items={generateYearItems()}
								onChange={() => {
									form.$("plannedProjectStart")
								}}
								disabled={isFormDisabled()}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<XsSearchSelect
								field={form.$("plannedProjectStartMonth")}
								items={generateMonthItems()}
								disabled={isFormDisabled()}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<XsInput field={form.$("plannedProjectCapacaty")} disabled={isFormDisabled()} />
						</Grid>
					</Grid>
					<Grid container direction="column" className="pt-2">
						<Grid item>
							<Typography variant="h6">Pillar *</Typography>
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorClearHydrogen")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorHydrogenDistribution")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorHydrogenMobility")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorHydrogenInIndustry")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorHydrogenInEnergy")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorHydrogenInHousing")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("sectorHydrogenComponents")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item className="pt-2">
							<XsTextArea field={form.$("plannedSector")} disabled={isFormDisabled()} />
						</Grid>
					</Grid>
					<Grid container direction="column">
						<Grid item>
							<Typography variant="h6">
								Type of hydrogen produced {form.$("sectorClearHydrogen").value ? " *" : ""}
							</Typography>
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("productionRenewable")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox field={form.$("productionLowCO2prod")} disabled={isFormDisabled()} />
						</Grid>
						<Grid item>
							<XsCheckbox
								field={form.$("productionOther")}
								disabled={isFormDisabled()}
								onChange={() => {
									if (form.$("productionOther").value) {
										form.$("productionOtherDescription").set("rules", "required")
									} else {
										form.$("productionOtherDescription").set("rules", "")
										form.$("productionOtherDescription").value = ""
									}
								}}
							/>
							{form.$("productionOther").value && <XsTextArea field={form.$("productionOtherDescription")} />}
						</Grid>

						{/* <Grid item xs={3}>
            <XsInput field={form.$("plannedProjectStart")} />
          </Grid> */}
					</Grid>
					<Grid container spacing={1} className="pt-2">
						<Grid item xs={12} md={6}>
							<XsNumberInput
								field={form.$("plannedInvestment")}
								step={"0.001"}
								min={"0.001"}
								endText={"mil.€"}
								disabled={isFormDisabled()}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<XsNumberInput
								field={form.$("plannedOpex")}
								step={"0.001"}
								min={"0.001"}
								endText={"mil.€"}
								disabled={isFormDisabled()}
							/>
						</Grid>
						<Grid item xs={12}>
							<XsTextArea field={form.$("projectSummary")} maxLength={3000} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<XsTextArea field={form.$("specificProjectDescription")} maxLength={7500} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<XsTextArea
								field={form.$("projectValueForHydrogenEcosystem")}
								maxLength={3000}
								disabled={isFormDisabled()}
							/>
						</Grid>
						<Grid item xs={12}>
							<XsTextArea field={form.$("plannedGoals")} maxLength={3000} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<XsTextArea field={form.$("projectEnablesPartnersTo")} maxLength={3000} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<XsTextArea field={form.$("projectLooksForPartnersFrom")} maxLength={3000} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<XsTextArea field={form.$("projectNeedsStatesHelp")} maxLength={3000} disabled={isFormDisabled()} />
						</Grid>
						<Grid item xs={12}>
							<XsTextArea field={form.$("projectAssumptions")} maxLength={3000} disabled={isFormDisabled()} />
						</Grid>
					</Grid>
					<Grid container spacing={2}>
						{/* <Grid item xs={6}>
            <XsNumberInput field={form.$("amount")} min="1" step="0.01" />
          </Grid> */}
						<Grid item xs={12}>
							<React.Fragment>
								<Typography variant="body1">
									Please download these mandatory document templates, fill in the documents and attach them to this
									application form - see dropzone below. Max size for each document is 500kB.
								</Typography>
								<Typography variant="body1">
									<b>Only these file types are allowed: doc, docx, xls, xlsx, pdf</b>
								</Typography>
							</React.Fragment>
						</Grid>
						<Grid item container>
							{isSafe(RequestFormStore.attachmentTemplates) &&
								RequestFormStore.attachmentTemplates
									.filter((row) => requiredAttachemnts.includes(row.attachment_type_id))
									.map((template, dropzoneIndex) => {
										return (
											<Grid item container spacing={2} key={template.attachment_type_id}>
												<Grid item xs={12}>
													<Link
														variant="body2"
														className="pointer bold"
														onClick={() => {
															delayedCallback(200, () => {
																RequestFormStore.downloadSpiecificTemplate(template.api_url, template.file_name)
															})
														}}
													>
														<i className="fas fa-file-download mr-1"></i>
														<span>{template.attachment_type_name}</span>
													</Link>
												</Grid>
												<Grid item xs={12}>
													<Dropzone
														onDrop={(files) => {
															handleDrop(files, dropzoneIndex, 1)
														}}
														id="dropzone"
														disabled={isFormDisabled()}
														accept={".doc, .docx, .xls, .xlsx, .pdf"}
													>
														{isSafe(RequestFormStore[`dropedFiles${dropzoneIndex}`]) &&
														RequestFormStore[`dropedFiles${dropzoneIndex}`].length ? (
															RequestFormStore[`dropedFiles${dropzoneIndex}`].map((file, index) => (
																<div key={index} className={"dropedFile"}>
																	<i
																		className="fal fa-file icon"
																		onClick={(e) => {
																			e.stopPropagation()
																			handleDropzonePreview(index, dropzoneIndex)
																		}}
																	/>
																	<i
																		className="far fa-trash-alt deleteIcon"
																		onClick={(e) => {
																			e.stopPropagation()
																			if (!isFormDisabled()) {
																				GlobalStore.openConfirmationDialog("xsDeleteAttachmentFromDropzone", {
																					fileIndex: index,
																					dropzoneIndex: dropzoneIndex
																				})
																			}
																		}}
																	/>
																	<br />
																	<div
																		className={"mac_ellipsis"}
																		data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
																		data-content-end={
																			"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"
																		}
																	/>
																</div>
															))
														) : (
															<div
																className={"dropzonePlaceholder"}
															>{`Drop ${template.attachment_type_name} here or click to upload file`}</div>
														)}
													</Dropzone>
												</Grid>
											</Grid>
										)
									})}
						</Grid>
						{/* <Grid item xs={12}>
							<Typography variant="body1">
								Other documents to download (can be used to help fill in the form correctly). These documents are not
								intended to be attached to the application.
							</Typography>
						</Grid> */}
						{/* <Grid item container spacing={1}>
							{isSafe(RequestFormStore.attachmentTemplates) &&
								RequestFormStore.attachmentTemplates
									.filter((row) => !requiredAttachemnts.includes(row.attachment_type_id))
									.map((template) => {
										return (
											<Grid item xs={12} key={template.attachment_type_id}>
												<Link
													variant="body2"
													className="pointer bold"
													onClick={() => {
														delayedCallback(200, () => {
															RequestFormStore.downloadSpiecificTemplate(template.api_url, template.file_name)
														})
													}}
												>
													<i className="fas fa-file-download mr-1"></i>
													<span>{template.attachment_type_name}</span>
												</Link>
											</Grid>
										)
									})}
						</Grid> */}
						{/* <Grid item xs={12}>
							<Dropzone
								onDrop={(files) => {
									handleDrop(files, "Other", 5)
								}}
								id="dropzone"
								disabled={isFormDisabled()}
								accept={".doc, .docx, .xls, .xlsx, .pdf"}
								// maxSize={500000}
							>
								{isSafe(RequestFormStore.dropedFilesOther) && RequestFormStore.dropedFilesOther.length ? (
									RequestFormStore.dropedFilesOther.map((file, index) => (
										<div key={index} className={"dropedFile"}>
											<i
												className="fal fa-file icon"
												onClick={(e) => {
													e.stopPropagation()
													handleDropzonePreview(index, "Other")
												}}
											/>
											<i
												className="far fa-trash-alt deleteIcon"
												onClick={(e) => {
													e.stopPropagation()
													if (!isFormDisabled()) {
														GlobalStore.openConfirmationDialog("xsDeleteAttachmentFromDropzone", {
															fileIndex: index,
															dropzoneIndex: "Other"
														})
													}
												}}
											/>
											<br />
											<div
												className={"mac_ellipsis"}
												data-content-start={file.name.substr(0, Math.floor(file.name.length / 2))}
												data-content-end={"\u200E" + file.name.substr(Math.floor(file.name.length / 2)) + "\u200E"}
											/>
										</div>
									))
								) : (
									<div className={"dropzonePlaceholder"}>Drop files here or click to upload files</div>
								)}
							</Dropzone>
						</Grid> */}
					</Grid>
				</Container>
				<Container maxWidth="lg" className="request_form_buttons">
					<Grid item container spacing={1}>
						<Grid item>
							<XsButton
								icon={<i className="fal fa-save"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										RequestFormStore.validateDraft(form, () => {
											RequestFormStore.generateDataAndSave(form, "draft", (id) => {
												RouterStore.push(`/request?id=${id}`)
												GlobalStore.loading(true)
												RequestFormStore.resetDropzones()
												form.reset()
												RequestFormStore.load(form, id)
											})
										})
									})
								}}
								disabled={
									!(form.$("status").value == "inp" || isEmpty(form.$("status").value)) ||
									GlobalStore.isRegistrationClose()
								}
								text={<FormattedMessage id="Common.btn.save" />}
							/>
						</Grid>
						<Grid item>
							<XsButton
								icon={<i className="fal fa-save"></i>}
								onClick={(e) => {
									delayedCallback(300, () => {
										form.onSubmit(e)
									})
								}}
								disabled={form.$("status").value == "sent" || GlobalStore.isRegistrationClose()}
								text="Submit (no further changes to form possible)"
							/>
						</Grid>
						<Grid item>
							<XsButton
								disabled={form.$("status").value != "sent"}
								icon={<i className="fal fa-print"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										RequestPrintStore.print(props.id)
										InfoStore.open(
											"Please enable pop-up windows (tabs) for this site, if new tab with generated PDF is not shown",
											<FormattedMessage id="Common.label.warning" />
										)
									})
								}}
								variant="outlined"
								text={<FormattedMessage id="Common.btn.print" />}
							/>
						</Grid>
						<Grid item>
							<XsButton
								icon={<i className="fal fa-times"></i>}
								onClick={() => {
									delayedCallback(300, () => {
										if (RequestFormStore.isSameFormValues()) {
											form.reset()
											RouterStore.push("/requests")
										} else {
											GlobalStore.openConfirmationDialog("xsCloseRequestForm")
										}
									})
								}}
								color="secondary"
								text={<FormattedMessage id="Common.btn.close" />}
							/>
						</Grid>
					</Grid>
				</Container>
			</Container>
			<XsConfirmationDialog
				name="xsDeleteAttachmentFromDropzone"
				title={<FormattedMessage id="Common.label.warning" />}
				text={<FormattedMessage id="Common.label.deleteAttachment" />}
				onConfirmation={() =>
					handleDropzoneDelete(
						GlobalStore.confirmationDialogParams.fileIndex,
						GlobalStore.confirmationDialogParams.dropzoneIndex
					)
				}
			/>
			<XsConfirmationDialog
				name="xsCloseRequestForm"
				text="Are you sure you want to leave and discard recent changes?"
				confirmBtn={{
					icon: <i className="fal fa-check" />,
					text: "Common.btn.yes"
				}}
				cancelBtn={{
					icon: <i className="fal fa-times" />,
					text: "Common.btn.no"
				}}
				onConfirmation={() => {
					form.reset()
					RouterStore.push("/requests")
				}}
			/>
		</React.Fragment>
	)
}

export default injectIntl(observer(RequestForm))
