import {observable, action} from "mobx"
import api from "../../global/actions/api"
import GlobalStore from "../../global/store/GlobalStore"
import RegistersCacheStore from "../../global/store/RegistersCacheStore"

class RequestFormStore {
	@observable dropedFiles0 = []
	@observable dropedFiles1 = []
	@observable dropedFiles2 = []
	@observable dropedFiles3 = []
	@observable dropedFilesOther = []
	@observable attachmentTemplates = []

	formRef = null

	defaultFormValues = {}

	@action isSameFormValues() {
		return JSON.stringify(this.defaultFormValues) === JSON.stringify(this.formRef.values())
	}

	@action deleteLoadedAttachemnt(id, dropzoneIndex, attachmentIndex) {
		let data = {
			_id: id,
			active: false
		}
		api
			.saveAttachment(data)
			.call()
			.then(() => {
				this[`dropedFiles${dropzoneIndex}`].splice(attachmentIndex, 1)
			})
	}

	@action resetDropzones() {
		this.dropedFiles0 = []
		this.dropedFiles1 = []
		this.dropedFiles2 = []
		this.dropedFiles3 = []
		this.dropedFilesOther = []
	}

	// @action async saveCompany(form) {
	// 	const formVals = form.values()
	// 	let req = {
	// 		active: true,
	// 		_id: isNotEmpty(formVals.company_id) ? formVals.company_id : null,
	// 		identifier: formVals.ico, //ICO
	// 		name: formVals.companyName,
	// 		short_name: formVals.companyName,
	// 		// "tax_identification_number": "7777777", //DIC
	// 		// "vat_identification_number": "SK777777", // IC DPH
	// 		description: formVals.contactDescription,
	// 		_ref: false,
	// 		_type: "Entity.Data.Company",
	// 		object_owner: "E:I:4",
	// 		specific_rels: [
	// 			{
	// 				_ref: false,
	// 				_id: isNotEmpty(formVals.orgunit_id) ? formVals.orgunit_id : null,
	// 				code: "01", //natvrdo 01
	// 				code_ext: "01",
	// 				name: formVals.companyName, //podla nazvu spolocnosti
	// 				name_ext: formVals.companyName, //podla nazvu spolocnosti
	// 				// "object_owner": "E:I:56416",
	// 				object_owner: "E:I:4",
	// 				_type: "Entity.Data.OrgUnit",
	// 				addresses: [
	// 					{
	// 						_type: "Entity.Data.Address",
	// 						_ref: false,
	// 						object_owner: "E:I:4",
	// 						type: "OA",
	// 						// "city_plain": formVals.companyCity,
	// 						obj_city: {_type: "Entity.CL.Address.City", _id: formVals.companyCity},
	// 						country: {_type: "Entity.CL.Country", _id: formVals.contactCountry},
	// 						region: {
	// 							_id: formVals.companyRegion
	// 						},
	// 						county: {
	// 							_id: formVals.companyCounty
	// 						},
	// 						obj_zip: null,
	// 						zip_plain: formVals.companyPSC,
	// 						street_plain: isNotEmpty(formVals.companyStreet) ? formVals.companyStreet : null,
	// 						house_number: isNotEmpty(formVals.companyStreetNumber) ? formVals.companyStreetNumber : null
	// 					}
	// 				]
	// 				//Save kontaktu presunuty do persony
	// 				// contacts: [
	// 				// 	{
	// 				// 		_ref: false,
	// 				// 		object_owner: "E:I:4",
	// 				// 		primary: true,
	// 				// 		contact_type: {
	// 				// 			_id: "business"
	// 				// 		},
	// 				// 		_type: "Entity.Data.Contact",
	// 				// 		email: isNotEmpty(formVals.mail) ? formVals.mail : null,
	// 				// 		phone: isNotEmpty(formVals.phone) ? formVals.phone : null
	// 				// 	}
	// 				// ]
	// 			}
	// 		]
	// 	}

	// 	let response = null
	// 	try {
	// 		response = await api.saveCompany(req).call()
	// 	} catch (error) {
	// 		response = null
	// 	}

	// 	return response
	// }

	generateDataAndSave(form, version, callback) {
		let droppedFiles = [
			...this.dropedFiles0,
			...this.dropedFiles1,
			...this.dropedFiles2,
			...this.dropedFiles3,
			...this.dropedFilesOther
		]
		if (isSafe(droppedFiles) && droppedFiles.length) {
			let req = []
			let files = droppedFiles
			var saveFunction = this.save
			// const rel_object_id = response._id
			let binaryDataArr = []
			let getdata = function(index) {
				let data = files[index]
				let binaryData
				var reader = new FileReader()
				reader.readAsBinaryString(data)
				reader.onloadend = function() {
					binaryData = reader.result
					binaryDataArr.push(binaryData)
					req.push({
						attachment_id: isNotEmpty(data._id) ? data._id : null,
						_ref: false,
						active: true,
						content_type: isNotEmpty(data.type) ? data.type : "application/octet-stream",
						// content: binaryData.split(",")[1], ???
						content: btoa(binaryData),
						type: data.databaseType,
						file_name: data.name,
						size: data.size
					})
					if (files.length > index + 1) {
						getdata(index + 1)
					} else {
						saveFunction(form, req, version, callback)
					}
				}
			}
			getdata(0)
		} else {
			this.save(form, [], version, callback)
		}
	}

	@action save(form, dropzoneData, version, callback) {
		GlobalStore.loading(true)
		const formVals = form.values()
		// const companyResponse = await this.saveCompany(form)

		let req = {
			record_id: isNotEmpty(formVals.request_id) ? formVals.request_id : null,
			_type: "IPCEI.API.JSON.Proxy.Request",
			version: isNotEmpty(version) ? version : "draft",
			status: isNotEmpty(version) && version == "final" ? "sent" : "inp",
			type_id: "IPCEI.Data.Request",
			attached_files: dropzoneData,
			// company: {
			// 	_id: companyResponse._id,
			// 	_type: "Entity.Data.Company"
			// },
			project_name: formVals.projectName,
			person_data: {
				first_name: formVals.name,
				last_name: formVals.surname,
				email: isNotEmpty(formVals.mail) ? formVals.mail : null,
				phone: isNotEmpty(formVals.phone) ? formVals.phone : null,
				_type: "IPCEI.API.JSON.Proxy.Person"
			},
			// "readiness_date": formVals.plannedProjectStart,
			readiness_period:
				isNotEmpty(formVals.plannedProjectStartYear) && isNotEmpty(formVals.plannedProjectStartMonth)
					? `${formVals.plannedProjectStartYear}${formVals.plannedProjectStartMonth}`
					: null,
			planned_capacity_desc: isNotEmpty(formVals.plannedProjectCapacaty) ? formVals.plannedProjectCapacaty : null,
			sector_description: isNotEmpty(formVals.plannedSector) ? formVals.plannedSector : null,
			planned_investment_scale: isNotEmpty(formVals.plannedInvestment) ? formVals.plannedInvestment : null,
			annual_opex: isNotEmpty(formVals.plannedOpex) ? formVals.plannedOpex : null,
			project_summary: formVals.projectSummary,
			detailed_description: formVals.specificProjectDescription,
			added_value_description: formVals.projectValueForHydrogenEcosystem,
			quantitative_goal: isNotEmpty(formVals.plannedGoals) ? formVals.plannedGoals : null,
			offerings_to_partner: formVals.projectEnablesPartnersTo,
			looking_for_partners: formVals.projectLooksForPartnersFrom,
			without_gov_reason: formVals.projectNeedsStatesHelp,
			assumption: formVals.projectAssumptions,
			// "amount": formVals.amount,
			other_hydrogen_type_desc: formVals.productionOtherDescription,
			region: isNotEmpty(formVals.applicantRegion) ? formVals.applicantRegion : null,
			city: isNotEmpty(formVals.applicantCity) ? formVals.applicantCity : null,
			// region_plain: isNotEmpty(formVals.applicantRegion) ? formVals.applicantRegion : null,
			// city_plain: isNotEmpty(formVals.applicantCity) ? formVals.applicantCity : null,
			country: isNotEmpty(formVals.applicantCountry) ? formVals.applicantCountry : null,
			project_acronym: formVals.projectAcronym
		}

		if (isNotEmpty(formVals.person_id)) {
			req.person_data["person"] = {
				_id: formVals.person_id,
				_type: "Entity.Data.Person"
			}
		}

		let sectors = []

		if (formVals.sectorClearHydrogen) {
			const hydroType = GlobalStore.CL["sectors"].find((row) => row.code == "hydrogenprod")
			sectors.push(hydroType._id)
		}
		if (formVals.sectorHydrogenDistribution) {
			const hydroType = GlobalStore.CL["sectors"].find((row) => row.code == "hydrogendist")
			sectors.push(hydroType._id)
		}
		if (formVals.sectorHydrogenMobility) {
			const hydroType = GlobalStore.CL["sectors"].find((row) => row.code == "hydrogenmobapp")
			sectors.push(hydroType._id)
		}
		if (formVals.sectorHydrogenInIndustry) {
			const hydroType = GlobalStore.CL["sectors"].find((row) => row.code == "hydrogenindapp")
			sectors.push(hydroType._id)
		}
		if (formVals.sectorHydrogenInEnergy) {
			const hydroType = GlobalStore.CL["sectors"].find((row) => row.code == "hydrogenenersecapp")
			sectors.push(hydroType._id)
		}
		if (formVals.sectorHydrogenInHousing) {
			const hydroType = GlobalStore.CL["sectors"].find((row) => row.code == "hydrogenresidapp")
			sectors.push(hydroType._id)
		}
		if (formVals.sectorHydrogenComponents) {
			const hydroType = GlobalStore.CL["sectors"].find((row) => row.code == "components")
			sectors.push(hydroType._id)
		}

		if (sectors.length) {
			req["sectors"] = sectors
		}

		let productionType = []
		if (formVals.productionRenewable) {
			const hydroType = GlobalStore.CL["hydrogenType"].find((row) => row.code == "renewable")
			productionType.push(hydroType._id)
		}
		if (formVals.productionLowCO2prod) {
			const hydroType = GlobalStore.CL["hydrogenType"].find((row) => row.code == "lowcarbon")
			productionType.push(hydroType._id)
		}
		if (formVals.productionOther) {
			const hydroType = GlobalStore.CL["hydrogenType"].find((row) => row.code == "oth")
			productionType.push(hydroType._id)
		}

		req["hydrogen_types"] = isSafe(productionType) ? productionType : null

		let activities = []

		if (formVals.activityTypeInfrastructure) {
			const hydroType = GlobalStore.CL["activityType"].find((row) => row.code == "infra")
			activities.push(hydroType._id)
		}
		if (formVals.activityTypeEquipProduction) {
			const hydroType = GlobalStore.CL["activityType"].find((row) => row.code == "eqman")
			activities.push(hydroType._id)
		}
		if (formVals.activityTypeHydrogenProduction) {
			const hydroType = GlobalStore.CL["activityType"].find((row) => row.code == "hydrogenprod")
			activities.push(hydroType._id)
		}
		if (formVals.activityTypeIndustryUse) {
			const hydroType = GlobalStore.CL["activityType"].find((row) => row.code == "industrapp")
			activities.push(hydroType._id)
		}
		if (formVals.activityTypeOther) {
			const hydroType = GlobalStore.CL["activityType"].find((row) => row.code == "oth")
			activities.push(hydroType._id)
			req["other_activity_type_desc"] = formVals.activityTypeOtherText
		}

		if (activities.length) {
			req["activity_types"] = activities
		}

		//pridanie company

		req["company_data"] = {
			company: isNotEmpty(formVals.company_id) ? formVals.company_id : null,
			description: isNotEmpty(formVals.contactDescription) ? formVals.contactDescription : "",
			identifier: formVals.ico,
			name: formVals.companyName,
			address: {
				country: {
					_type: "Entity.CL.Country",
					_id: formVals.contactCountry
				},
				county: {
					_type: "Entity.CL.Address.County",
					_id: formVals.companyCounty
				},
				description: "",
				house_number: isNotEmpty(formVals.companyStreetNumber) ? formVals.companyStreetNumber : null,
				obj_city: {
					_type: "Entity.CL.Address.City",
					_id: formVals.companyCity
				},
				primary: true,
				region: {
					_type: "Entity.CL.Address.Region",
					_id: formVals.companyRegion
				},
				street_plain: formVals.companyStreet,
				type: {
					_type: "Entity.CL.AddressType",
					_id: "OA"
				},
				zip_plain: formVals.companyPSC
			}
		}

		api
			.saveRequest(req)
			.call()
			.then((response) => {
				GlobalStore.setNotificationMessage("Record saved successfully", "", "success")
				if (isSafe(callback)) {
					callback(response._id)
				}
			})
	}

	@action loadAttachemntTemplates() {
		api
			.loadAttachmentTemplates()
			.call()
			.then((response) => {
				if (isSafe(response)) {
					this.attachmentTemplates = response.sort((a, b) => a.item_order - b.item_order)
				}
			})
	}

	@action load(form, id) {
		GlobalStore.loading(true)
		api
			.loadRequestWithAtt(id)
			.call()
			.then((response) => {
				form.reset()
				const company = response.company_data
				// const orgunit = response.company.specific_rels.find((row) => row._type == "Entity.Data.OrgUnit")
				const addresses = company.address
				let formObj = {
					request_id: id,
					company_id: company.company,
					// orgunit_id: orgunit._id,
					status: response.status._id,
					projectName: response.project_name,
					plannedProjectStart: response.readiness_period,
					plannedProjectCapacaty: response.planned_capacity_desc,
					plannedSector: response.sector_description,
					plannedInvestment: response.planned_investment_scale,
					plannedOpex: response.annual_opex,
					projectSummary: response.project_summary,
					specificProjectDescription: response.detailed_description,
					projectValueForHydrogenEcosystem: response.added_value_description,
					plannedGoals: response.quantitative_goal,
					projectEnablesPartnersTo: response.offerings_to_partner,
					projectLooksForPartnersFrom: response.looking_for_partners,
					projectNeedsStatesHelp: response.without_gov_reason,
					projectAssumptions: response.assumption,
					// amount: response.amount,
					ico: company.identifier,
					companyName: company.name,
					contactDescription: company.description,
					companyPSC: addresses.zip_plain,
					companyStreet: isSafe(addresses.street_plain) ? addresses.street_plain : "",
					companyStreetNumber: isSafe(addresses.house_number) ? addresses.house_number : "",
					contactCountry: isSafe(addresses.country) ? addresses.country : "",
					companyRegion: isSafe(addresses.region) ? addresses.region : "",
					companyCounty: isSafe(addresses.county) ? addresses.county : "",
					companyCity: isSafe(addresses.obj_city) ? addresses.obj_city : "",
					productionOtherDescription: response.other_hydrogen_type_desc,
					plannedProjectStartYear: isNotEmpty(response.readiness_period) ? response.readiness_period.slice(0, 4) : "",
					plannedProjectStartMonth: isNotEmpty(response.readiness_period) ? response.readiness_period.slice(-2) : "",
					applicantRegion: isSafe(response.region) ? response.region._id : "",
					applicantCity: isSafe(response.city) ? response.city._id : "",
					// applicantRegion: isSafe(response.region_plain) ? response.region_plain : "",
					// applicantCity: isSafe(response.city_plain) ? response.city_plain : "",
					applicantCountry: isSafe(response.country) ? response.country._id : "",
					projectAcronym: response.project_acronym,
					person_id:
						isSafe(response.person_data) && isSafe(response.person_data.person) ? response.person_data.person : "",
					mail: isSafe(response.person_data) ? response.person_data.email : "",
					phone: isSafe(response.person_data) ? response.person_data.phone : "",
					name: isSafe(response.person_data) ? response.person_data.first_name : "",
					surname: isSafe(response.person_data) ? response.person_data.last_name : ""
				}

				if (isSafe(response.sectors) && response.sectors.length) {
					response.sectors.forEach((sector) => {
						if (sector.code == "hydrogenprod") {
							formObj.sectorClearHydrogen = true
						} else if (sector.code == "hydrogendist") {
							formObj.sectorHydrogenDistribution = true
						} else if (sector.code == "hydrogenmobapp") {
							formObj.sectorHydrogenMobility = true
						} else if (sector.code == "hydrogenindapp") {
							formObj.sectorHydrogenInIndustry = true
						} else if (sector.code == "hydrogenenersecapp") {
							formObj.sectorHydrogenInEnergy = true
						} else if (sector.code == "hydrogenresidapp") {
							formObj.sectorHydrogenInHousing = true
						} else if (sector.code == "components") {
							formObj.sectorHydrogenComponents = true
						}
					})
				}

				if (isSafe(response.hydrogen_types) && response.hydrogen_types.length) {
					response.hydrogen_types.forEach((hydrogen) => {
						const hydroCode = hydrogen.code
						if (hydroCode == "renewable") {
							formObj.productionRenewable = true
						} else if (hydroCode == "lowcarbon") {
							formObj.productionLowCO2prod = true
						} else if (hydroCode == "oth") {
							formObj.productionOther = true
						}
					})
				}

				if (isSafe(response.activity_types) && response.activity_types.length) {
					response.activity_types.forEach((sector) => {
						if (sector.code == "infra") {
							formObj.activityTypeInfrastructure = true
						} else if (sector.code == "eqman") {
							formObj.activityTypeEquipProduction = true
						} else if (sector.code == "hydrogenprod") {
							formObj.activityTypeHydrogenProduction = true
						} else if (sector.code == "industrapp") {
							formObj.activityTypeIndustryUse = true
						} else if (sector.code == "oth") {
							formObj.activityTypeOther = true
							formObj.activityTypeOtherText = response.other_activity_type_desc
						}
					})
				}

				if (isSafe(response.attached_files) && response.attached_files.length) {
					response.attached_files.forEach((attachment) => {
						const blob = b64toBlob(attachment.content)
						let tmp = new Blob([blob], {
							type: attachment.content_type
						})
						tmp.name = attachment.file_name
						tmp._id = attachment.attachment_id
						tmp.databaseType = attachment.type

						if (tmp.databaseType == "annexsd") {
							this.dropedFiles2.push(tmp)
						} else if (tmp.databaseType == "annexepb") {
							this.dropedFiles1.push(tmp)
						} else if (tmp.databaseType == "annexppt") {
							this.dropedFiles0.push(tmp)
						} else if (tmp.databaseType == "sumprojsk") {
							this.dropedFiles3.push(tmp)
						}
					})
				}

				form.set(formObj)
				this.defaultFormValues = form.values()
				GlobalStore.loading(false)
			})
	}

	downloadSpiecificTemplate(uri, name) {
		api.downloadFile(uri, name)
	}

	validateDraft(form, callback) {
		const formVals = form.values()
		if (
			isEmpty(formVals.companyName) ||
			isEmpty(formVals.ico) ||
			isEmpty(formVals.ico) ||
			isEmpty(formVals.contactCountry) ||
			isEmpty(formVals.companyRegion) ||
			isEmpty(formVals.companyCounty) ||
			isEmpty(formVals.companyCity) ||
			isEmpty(formVals.companyPSC) ||
			isEmpty(formVals.name) ||
			isEmpty(formVals.surname) ||
			isEmpty(formVals.mail) ||
			isEmpty(formVals.phone)
		) {
			GlobalStore.setNotificationMessage(
				"Applicant identification data and Contact person data are required!",
				null,
				"error"
			)
		} else {
			callback()
		}
	}

	loadCodelistAndForm(form, id) {
		RegistersCacheStore.checkRegisters("/request")
		this.load(form, id)
	}
}

var singleton = new RequestFormStore()
export default singleton
