"use strict"

import {observable, action} from "mobx"

import {getFilters} from "../../global/helpers/api"
import {getPersonId} from "../../global/helpers/actions"
import api from "../../global/actions/api"

import GlobalStore from "../../global/store/GlobalStore"

class RequestStore {
	@observable data = []

	@action getRequests() {
		GlobalStore.loading(true, "Loading applications...")

		const req = getFilters([`person_id=${getPersonId()}`, `type_id=IPCEI.Data.MicroElectronics`])

		api
			.getRequests(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows)) {
					this.data = res.rows
				} else {
					this.data = []
				}

				GlobalStore.loading(false)
			})
	}
}

var singleton = new RequestStore()
export default singleton
